/* -------------------- Pin -------------------- */
.pin {
  position: absolute;
  left: calc(50% - 10px);
  top: 3%;
  background-image: url("../../assets/img/pin_red_100x107.png");
  z-index: 10;
}
.pin-body {
  width: 20px;
  height: 20px;
  transform-origin: 40% 45%;
}
.pin-shadow {
  width: 20px;
  height: 25px;
  background-image: url("../../assets/img/pin-shadow.png");
  position: absolute;
  top: 5%;
  left: calc(50% - 10px);
}

/* -------------------- Photo - Polaroid -------------------- */
.pic-frame-polaroid {
  position: absolute;
  z-index: 2;
  width: 100%;
  height: 100%;
  -webkit-box-shadow: 0px 0px 3px 0px rgba(0, 0, 0, 0.5);
  -moz-box-shadow: 0px 0px 3px 0px rgba(0, 0, 0, 0.5);
  box-shadow: 0px 0px 3px 0px rgba(0, 0, 0, 0.5);
}

.pic-polaroid {
  width: 89%;
  height: 76%;
  margin: 5%;
  position: absolute;
  -webkit-filter: brightness(1.13) contrast(0.8) saturate(0.66) sepia(0.16);
  -moz-filter: brightness(1.13) contrast(0.8) saturate(0.66) sepia(0.16);
  filter: brightness(1.13) contrast(0.8) saturate(0.66) sepia(0.16);
  -webkit-box-shadow: inset 0px 0px 30px 0px rgba(0, 0, 0, 1);
  -moz-box-shadow: inset 0px 0px 30px 0px rgba(0, 0, 0, 1);
  box-shadow: inset 0px 0px 30px 0px rgba(0, 0, 0, 1);
}

.pic-label-polaroid {
  position: absolute;
  bottom: 2%;
  display: block;
  text-align: center;
  width: 100%;
  color: #142cde;
  z-index: 3;
  font-family: "Sue Ellen Francisco", Arial, cursive;
  font-size: 1em;
}

/* -------------------- Photograph with Border -------------------- */

.photo2 {
  width: 100%;
  height: 100%;
  -webkit-box-shadow: inset 0px 0px 30px 0px rgba(0, 0, 0, 1);
  -moz-box-shadow: inset 0px 0px 30px 0px rgba(0, 0, 0, 1);
  box-shadow: inset 0px 0px 30px 0px rgba(0, 0, 0, 1);
}

/* -------------------- Notepad Page -------------------- */
.notepad-page {
  font-size: 0.5em;
  font-family: "Sue Ellen Francisco", Arial, cursive;
  color: #142cde;
  padding: 2.5em 1em 2em 2.5em;
  line-height: 1em;
  width: 100%;
  height: 100%;
}

/* -------------------- PostIt Note -------------------- */
.postit {
  font-size: 1.5em;
  font-family: "Sue Ellen Francisco", Arial, cursive;
  color: #142cde;
  padding: 1.5em 0.5em 0.5em;
  line-height: 1em;
  width: 100%;
  height: 100%;
}

/* -------------------- Notepad Page -------------------- */
.notepad-page {
  font-size: 0.5em;
  font-family: "Sue Ellen Francisco", Arial, cursive;
  color: #142cde;
  padding: 2.5em 1em 2em 2.5em;
  line-height: 1em;
  width: 100%;
  height: 100%;
}

/* -------------------- Newspaper - Column Cutting -------------------- */
.newspaper-column {
  font-size: 0.25em;
  font-family: "Source Serif Pro Regular", "Times New Roman", cursive;
  text-align: justify;
  color: #1b1212;
  padding: 1.5em 1em 2em 1em;
  line-height: 1em;
  width: 100%;
  height: 100%;
  overflow: hidden;
  -webkit-filter: grayscale(100%) sepia(0.39);
  filter: grayscale(100%) sepia(0.39);
}
.newspaper-column-headline {
  font-family: "Vidaloka Regular", "Times New Roman", serif;
  font-size: 2em;
  letter-spacing: -0.5px;
  line-height: 1em;
  text-align: left;
  margin-bottom: 0.5em;
}

/* -------------------- Newspaper - Front Page 1 -------------------- */
.newspaper-front-1 {
  font-size: 0.25em;
  font-family: "Source Serif Pro Regular", "Times New Roman", cursive;
  text-align: justify;
  color: #3e3e3e;
  padding: 1.5em 1em 2em 1em;
  line-height: 1em;
  width: 100%;
  height: 100%;
  overflow: hidden;
  -webkit-filter: grayscale(100%) sepia(0.39);
  filter: grayscale(100%) sepia(0.39);
}
.newspaper-front-1-name-bar {
  display: flex;
  justify-content: space-between;
  text-align: center;
}
.newspaper-front-1-name-box {
  width: 10%;
  border: 1px solid;
  margin-bottom: 0.8em;
  text-transform: uppercase;
  font-size: 0.8em;
  font-family: "Vidaloka Regular", "Times New Roman", serif;
  display: flex;
  flex-direction: column;
  justify-content: center;
}
.newspaper-front-1-name {
  font-family: "Chomsky", "Times New Roman", serif;
  font-size: 4em;
  letter-spacing: -0.5px;
  line-height: 1em;
}
.newspaper-front-1-strap {
  font-family: "Vidaloka Regular", "Times New Roman", serif;
  font-size: 1em;
  line-height: 1em;
  margin-top: 0.5em;
  margin-bottom: 0.5em;
  text-transform: uppercase;
}
.newspaper-front-1-bar {
  display: flex;
  justify-content: space-between;
  border-style: double;
  border-width: 3px;
  border-left-width: 0;
  border-right-width: 0;
  padding: 0.25em 0;
}
.newspaper-front-1-headline {
  font-family: "Vidaloka Regular", "Times New Roman", serif;
  font-size: 4em;
  letter-spacing: -0.5px;
  line-height: 1em;
  text-align: left;
  margin: 0.25em 0;
}
.newspaper-front-1-body {
  column-count: 3;
  display: inline-block;
  width: 66%;
  height: 60%;
  vertical-align: top;
}
.newspaper-front-1-image {
  display: inline-block;
  width: 32%;
  height: 60%;
  margin-left: 1em;
}
